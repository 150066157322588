import React from "react";

import Header from "./header";
import Footer from "./footer";

import "./layout.css";

const Layout = ({ children, menu, title }) => (
  <div className="content">
    <Header menu={menu} title={title} />
    <main>{children}</main>
    <Footer />
  </div>
);

export default Layout;
