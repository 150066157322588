import React from "react";
import SmartLink from "./smart-link";

import * as styles from "./footer.module.css";

const Footer = () => (
  <footer>
    <div className={styles.left}>
      <a href="mailto:matt@mnewton.com">matt@mnewton.com</a>
    </div>
    <div className={styles.right}>
      <SmartLink to="/feed.xml">RSS</SmartLink>
    </div>
  </footer>
);

export default Footer;
