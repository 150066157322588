// extracted by mini-css-extract-plugin
export var headingFontFamily = "\"Helvetica Neue\", Helvetica, sans-serif";
export var linkColor = "#553D8A";
export var breakpointMedium = "(min-width: 600px) and (max-width: 959px)";
export var breakpointLarge = "(min-width: 960px)";
export var left = "header-module--left--V28ED";
export var right = "header-module--right--wTMts";
export var title = "header-module--title--9O37w";
export var link = "header-module--link--RQ7iE";
export var linkButton = "header-module--link-button--zIwoY";
export var header = "header-module--header--mL307";