import React from "react";

import SmartLink from "./smart-link";
import DarkToggle from "./dark-toggle";

import * as styles from "./header.module.css";

const Header = ({ menu }) => (
  <header className={styles.header}>
    <div className={styles.left}>
      <SmartLink className={styles.title} to="https://www.mnewton.com/">
        MN
      </SmartLink>
    </div>
    <div className={styles.right}>
      <div className={styles.link}>
        <DarkToggle className={styles.linkButton} />
      </div>
      {menu.map(([label, url]) => (
        <div key={url} className={styles.link}>
          <SmartLink to={url}>{label}</SmartLink>
        </div>
      ))}
    </div>
  </header>
);

export default Header;
