// Static queries break so easily and inexplicably that it's preferable to just
// keep the metadata here. Screw it.
export const useSiteMetadata = () => ({
  title: "Matthew Sojourner Newton",
  author: "Matthew Sojourner Newton",
  description: "IT Consultant, &c.",
  siteUrl: "https://www.mnewton.com/",
  social: [["github", "mnewt"]],
  menu: [
    ["Blog", "/"],
    ["Projects", "https://www.mnewton.com/projects/"],
    ["Consulting", "https://www.mnewton.com/consulting/"],
  ],
  keywords: [
    "blog",
    "consulting",
    "it",
    "vmware",
    "virtualization",
    "cloud",
    "datacenter",
    "architect",
    "architecture",
  ],
});

// import { useStaticQuery, graphql } from "gatsby";

// export const useSiteMetadata = () => {
//   const { site } = useStaticQuery(
//     graphql`
//       query SiteMetaData {
//         site {
//           siteMetadata {
//             title
//             author
//             description
//             siteUrl
//             social
//             menu
//             keywords
//           }
//         }
//       }
//     `
//   );

//   return site.siteMetadata;
// };
