import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useSiteMetadata } from "../hooks/use-site-metadata";

const Head = ({ description, keywords, lang, meta, title }) => {
  const metadata = useSiteMetadata();

  description = description || metadata.description;
  keywords = keywords || metadata.keywords;
  lang = lang || "en-US";
  meta = meta || [];
  title = title || metadata.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={title === metadata.title ? `%s` : `%s | ${metadata.title}`}
      meta={[
        {
          name: `description`,
          content: "hi",
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: "description",
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  );
};

export default Head;

Head.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};
