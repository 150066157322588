import React, { useState, useEffect } from "react";

// Toggle dark and light themes.

// The actual dark and light themes are defined in CSS as [data-theme="dark"]
// and [data-theme="light"].

const DarkToggle = ({ className }) => {
  // This part runs during browser and server side rendering, so we can't assume
  // we have browser DOM, hence we don't properly initialize the theme yet.
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    // This only runs in the actual browser, so we can assume the DOM is available.
    const doc = document.documentElement;
    const storage = window.localStorage;

    // Restore the theme if the user previously set it explicitly.
    if (doc.getAttribute("data-theme")) {
      doc.setAttribute("data-theme", theme);
      storage.setItem("theme", theme);
      setTheme(
        doc.getAttribute("data-theme") ||
          storage.getItem("theme") ||
          (window.matchMedia("(prefers-color-scheme: dark)").matches
            ? "dark"
            : "light")
      );
    } else {
      // Keep the [data-theme] attribute and localStorage["theme"] in sync,
      // since that is effectively the user's instruction.
      doc.setAttribute("data-theme", storage.getItem("theme"));
    }
  }, [className, theme]);

  return (
    <button
      className={className}
      onClick={() => {
        setTheme(
          theme === "dark" ||
            document.documentElement.getAttribute("data-theme") === "dark" ||
            window.matchMedia("(prefers-color-scheme: dark)").matches
            ? "light"
            : "dark"
        );
      }}
    >
      {theme === "dark" ||
      (typeof document !== "undefined" &&
        document.documentElement.getAttribute("data-theme") === "dark") ||
      (typeof window !== "undefined" &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
        ? `☼`
        : `☽`}
    </button>
  );
};

export default DarkToggle;
