import React from "react";
import { Link } from "gatsby";
import { useSiteMetadata } from "../hooks/use-site-metadata";

const SmartLink = ({ children, className, to }) => {
  const { siteUrl } = useSiteMetadata();

  if (to.startsWith(siteUrl)) {
    // Make the link relative
    return (
      <Link className={className} to={to.substring(siteUrl.length - 1)}>
        {children}
      </Link>
    );
  } else if (/^(?:(?:[a-z]+:)?\/\/)|mailto:/i.test(to)) {
    // Don't generate a Gatsby Link, use an `a` tag instead
    return (
      <a className={className} href={to}>
        {children}
      </a>
    );
  } else {
    // No special case, use a Gatsby Link
    return (
      <Link className={className} to={to}>
        {children}
      </Link>
    );
  }
};

export default SmartLink;
